<template>
  <v-dialog v-model="state.dialog" scrollable persistent width="488">
    <validation-observer>
      <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
        <v-card class="modal">
          <v-card-title class="modal-title">Editar Doador</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <div class="mx-7 mt-5">
              <h3 class="mb-4">Dados Pessoais</h3>
              <v-row dense>
                <v-col cols="12">
                  <field
                    v-model="form.name"
                    type="genericText"
                    label="Nome completo"
                    name="name"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <field
                    v-model="form.cpf"
                    type="cpf"
                    label="CPF"
                    name="cpf"
                    :isRequired="false"
                  />
                </v-col>
              </v-row>

              <h3 class="mb-4">Contato</h3>
              <v-row dense>
                <v-col cols="12">
                  <field
                    v-model="form.email"
                    type="email"
                    label="E-mail"
                    name="email"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
              <phone-field v-model="form.phones" :isRequired="true" />
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 cancel-button black-3--text"
              color="#ffffff"
              :block="$isMobile"
              @click="state.dialog = false"
              >Cancelar</v-btn
            >
            <v-btn
              type="submit"
              color="secondary-color-1"
              :block="$isMobile"
              class="ma-0 mt-1 next-step-button white--text"
              :disabled="invalid"
              >Atualizar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
  import { ValidationObserver } from "vee-validate";

  import Field from "@/modules/core/views/components/Field";
  import PhoneField from "@/modules/core/views/components/PhoneField";

  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  export default {
    name: "EditDonatorInfoPFDialog",

    components: {
      Field,
      PhoneField,
      ValidationObserver
    },

    props: {
      state: {
        type: Object,
        required: true
      },

      donator: {
        type: Object,
        required: true
      }
    },

    computed: {
      form() {
        return {
          name: this.donator.name,
          cpf: this.donator?.cpf,
          email: this.donator.email,
          phones: this.donator.phones
        };
      }
    },

    methods: {
      submitForm() {
        const data = {
          ...this.form,
          phones: preparePhonesToSubmit(this.form?.phones)
        };
        this.$emit("submitForm", data);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
