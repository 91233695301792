<template>
  <v-card class="table-page">
    <template v-if="requestDonations.length">
      <v-card-title class="pl-5 pt-5 pb-0 mb-6">
        <h2>Solicitações</h2>
      </v-card-title>
      <v-divider />
      <div class="d-flex flex-column justify-space-between content">
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="requestDonations"
            hide-default-footer
          >
            <template v-slot:item.batch.batchNumber="{ item }">
              <template v-if="item.batch && item.batch.batchNumber">
                {{ item.batch.batchNumber }}
              </template>
              <template v-else>
                {{ "Não registrado" }}
              </template>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-center">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </v-card-actions>
      </div>
    </template>
    <template v-else>
      <div class="inherit-height d-flex justify-content-center">
        <data-not-found
          title="Você ainda não cadastrou nenhuma solicitação, deseja cadastrar?"
          btnLabel="Cadastrar a primeira solicitação"
          canSeeAction
          :btnAction="goToAddRequest"
          :imgSrc="require('@/assets/collection-request.png')"
        />
      </div>
    </template>
  </v-card>
</template>

<script>
  import { mapActions } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";

  import Pagination from "@/modules/core/views/components/Pagination";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  import masksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "RequestDonations",

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    mixins: [TableHandler, masksMixin],

    components: { DataNotFound, Pagination },

    data: () => ({
      headers: [
        {
          text: "Lote",
          align: "start",
          value: "batch.batchNumber"
        },
        { text: "Data/Hora", value: "createdAt" },
        { text: "Tipo", value: "type.description" },
        { text: "Status", value: "situation.description" }
      ],
      requestDonations: []
    }),

    async mounted() {
      await this.fetchRequestDonations();
    },

    methods: {
      ...mapActions("donator", ["getRequestDonations"]),
      ...mapActions(["toggleSnackbar"]),

      async fetchRequestDonations() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getRequestDonations({
            id: this.id,
            data: payload
          });
          this.requestDonations = this.formatData(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      async updateData() {
        await this.fetchRequestDonations();
      },

      formatData(data) {
        return data.map(item => ({
          ...item,
          createdAt: this.parseDate(item.createdAt, "dd/MM/yyyy - HH:mm")
        }));
      },

      goToAddRequest() {
        this.$router.push({
          name: "newRequest"
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    min-height: 592px;
  }
</style>
