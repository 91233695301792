<template>
  <p class="ma-0" :class="formatedClassColor">{{ formatedPhones }}</p>
</template>

<script>
  import masksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "PhoneInfo",

    mixins: [masksMixin],

    props: {
      phones: {
        type: Array,
        required: true
      },
      color: {
        type: String,
        required: false,
        default: () => "black-3"
      }
    },

    computed: {
      formatedPhones() {
        return this.phones.map(phone => this.parsePhone(phone)).join(", ");
      },

      formatedClassColor() {
        return {
          [`${this.color}--text`]: true
        };
      }
    }
  };
</script>

<style lang="scss" scoped></style>
